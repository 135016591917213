




























.cls-1,.cls-2
    fill none
    stroke-width 5px
.cls-1
    stroke #292828
    stroke-linecap round
    stroke-linejoin round
    stroke-dasharray 0 10
.cls-2
    stroke #000
    stroke-miterlimit 10
